<template>
    <div class="view">
        <header>
            <i class="hwiconfont icon-fanhui" @click="close()"></i>
        </header>
        <ul class=" dy-wrap">
            <p class="dy-title ">选择要举报的类型</p>
            <li class="list" :class="{'select':m.id == selectId}" v-for="(m, index) in ffList" @click="selectId=m.id">
                <div class="title" style="font-weight: normal;">{{m.value}}</div>
                <i class="hwiconfont icon-select_full"></i>
            </li>
            <li class="list tu flex-col">
                <div class="title">补充描述</div>
                <div class="content">
                    <textarea name="" placeholder="详细说明举报内容吧" id="content" v-model="content"></textarea>
                </div>
            </li>
            <li class="list tu flex-col">
                <div class="title">联系方式</div>
                <div class="content">
                    <input type="text" placeholder="填写你的联系方式，便于我们与你联系" v-model="phone">
                </div>
            </li>
        </ul>
        <div class="flex-c">
            <el-button style="width: 70vw;margin-top: 50px;" round type="primary" @click="submit()">提交</el-button>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                uid: '',
                ffList: [],
                selectId: 0,
                touserid: '',
                phone: '',
                content: '',
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.uid = this.$route.query.uid;
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getReason();
        },
        methods: {
            // ...mapMutations([])
            async getReason() {
                let ret = await this.$y_ajax('api/user/reportSelected')
                if (ret.code == 200) {
                    this.ffList = ret.result;
                }
            },
            async submit() {
                if (!this.content) {
                    this.$y_msg('举报内容不能为空');
                    return;
                }
                if (!this.selectId) {
                    this.$y_msg('请选择要举报的类型');
                    return;
                }
                if (!this.phone) {
                    this.$y_msg('联系方式不能为空');
                    return;
                }
                this.$y_loading();
                var param = {
                    type: 1,
                    report_user: this.uid, //被举报人id
                    report_selected_id: this.selectId,
                    desc: this.content,
                    phone: this.phone,
                    user_id: this.$y_getKey('userid')
                }

                let ret = await this.$y_ajax('api/user/reportUser', param)
                this.$y_loadingClose();
                var msg = ret.code == 200 ? '举报信息已提交，等待平台处理' : ret.msg;
                this.$y_msg(msg);
                let _this = this;
                setTimeout(() => {
                    _this.close();
                }, 1500)
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .dy-title {
        padding: 10px;
        font-weight: bold;
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border-bottom: 1px solid #eee;
        padding: 10px;
        font-size: 14px;

        .content {
            width: 100%;

            input {
                width: 100%;
                text-align: left;
                margin-top: 10px;
            }
        }



        .title {
            font-weight: bold;
            margin-right: 10px;

            color: #333333;
            word-break: keep-all;
        }

        .icon-select_full {
            font-size: 20px;
            color: #eee;
        }

        &.select {
            .icon-select_full {
                color: #fd5621;
            }
        }

        &.tu {
            align-items: flex-start;
        }
    }

    .text {
        font-size: 14px;
        background: #fff;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .content {
        textarea {
            margin-top: 10px;
            resize: none;
            width: 100%;
            height: 100px;
            line-height: 20px;
        }
    }
</style>